<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

export default {
  page: {
    title: "Discussions archivées",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Discussions archivées",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Discussion",
          active: true,
        },
      ],
      enAttentes: 0,
      effectues: 0,
      order: [],
      ordersData: [],
      orderPaid:[],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterVirement:null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "utilisteur", sortable: true, label: "Utilisteurs" },
        { key: "message", sortable: true, label: "Messages" },
        { key: "date", sortable: true, label: "Date" },
        { key: "action", label: "Actions" },
      ],
      discussion:[
        {utilisteur:"AGBO Martin", message:"  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo illo explicabo expedita magni provident nihil minus earum accusantium, dolorem cum ex porro laudantium deleniti magnam obcaecati amet incidunt architecto eveniet?",
    date:"12-05-2019"
    },
    {utilisteur:"AGBO Martin", message:"  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo illo explicabo expedita magni provident nihil minus earum accusantium, dolorem cum ex porro laudantium deleniti magnam obcaecati amet incidunt architecto eveniet?",
    date:"12-05-2019"
    },
      ],
     
      showConfirm: false,
      showConfirmT: false,
      showConfirmW: false,
      idVirement: 0,
      showManuel: false,
      showSucessVirement: false,
      showEchecVirement: false,
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.orderPaid = [];
      this.ordersData = [];
      const historique = await apiRequest(
        "GET",
        "admin/virements",
        undefined,
        false
      );
      if (historique && historique.data) {
        historique.data.virements.forEach((element) => {
          if (element.typeVirement == "VIREMENT") {
            var virement = {
              idVirement: element.id,
              trajet: element.trajet
                ? element.trajet.lieuDepart.nom +
                  " >> " +
                  element.trajet.lieuArrivee.nom
                : "",
              conducteur:
                element.conducteur.personne.lastname +
                " " +
                element.conducteur.personne.firstname,
              tel:
                element.conducteur.personne.indicatifTel +
                "" +
                element.conducteur.personne.tel,
              date:
                element.dateVirement == null
                  ? ""
                  : new Date(element.dateVirement).toLocaleString("fr-FR"),
              montant: element.montant,
              moyen: element.moyen,
              depart: element.trajet
                ? new Date(element.trajet.dateHeureDepart).toLocaleString(
                    "fr-FR"
                  )
                : "",
              place: element.place,
              about: element.statut,
              admin:
                element.admin === undefined
                  ? ""
                  : element.admin.personne.lastname +
                    " " +
                    element.admin.personne.firstname,
              auto: element.isAuto == true ? "AUTO" : "MANUEL",
              indicatifTel: element.conducteur.personne.indicatifTel,
            };
            if (element.statut == "PAID") {
              this.orderPaid.push(virement);
            }
            if (element.statut == "PENDING") {
              this.ordersData.push(virement);
            }
          }
        });
        this.enAttentes = this.ordersData.length;
        this.effectues = this.orderPaid.length;
      }
    },
    confirmeVirer(row) {
      this.idVirement = row.idVirement;
      this.showConfirm = true;
    },
    confirmeVirerT(row) {
      this.idVirement = row.idVirement;
      this.showConfirmT = true;
    },
 

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Discussions archivées</h5>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                <div class="input ">
                    <input
                      type="search"
                      id="serchInput"
                      placeholder="Rechercher..."
                      v-model="filterVirement"
                    />
                </div>
      
              </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="discussion"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filterVirement"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                
                    <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);"
                        class="btn btn-confirm d-flex align-items-center"
                        v-b-tooltip.hover
                        title="Arrêter la discussion"
                      >
                        <i class="mdi mdi-check font-size-18"></i> <span>Arrêter</span> 
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="effectues"
                          :per-page="perPage"
                          :rows="orderPaid"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showConfirm"
      title="Voulez-vous vraiment faire un virement Automatique à cet utilisateur?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirm = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virer"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showManuel"
      title="Voulez-vous vraiment faire un virement Manuel à cet utilisateur?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showManuel = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virementManuel"
          >Oui</b-button
        >
      </div>
    </b-modal>


  </Layout>
</template>

<style>

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.btn-confirm{
border: 1px solid rgba(21,71,52);
color:rgba(21,71,52);
}
.btn-confirm:hover{
border: none;
background:rgba(21,71,52);
color: #fff;
}
</style>
